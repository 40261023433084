/**
 * @file Zoom setup functionality for the Padlet app.
 */

import device from '@@/bits/device'
import { captureException } from '@@/bits/error_tracker'
import { fetchJson } from '@@/surface/api_fetch'
import type { BeethovenContentCategory, BeethovenContentSubcategory } from '@padlet/beethoven-client/src/types'
import { HTTPMethod } from '@padlet/fetch'

export enum ZoomCollaborateStatus {
  notStarted = 'notStarted',
  started = 'started',
  stopped = 'stopped',
  joined = 'joined',
  left = 'left',
}

export type ZoomRole = 'host' | 'coHost' | 'attendee' | 'panelist'

export async function initializeZoomSdk(): Promise<void> {
  const zoomSdk = (await import('@zoom/appssdk')).default
  await zoomSdk.config({
    popoutSize: { width: 480, height: 360 },
    capabilities: [
      'openUrl',
      'onRunningContextChange',
      'getRunningContext',
      'getUserContext',
      'expandApp',
      'startCollaborate',
      'endCollaborate',
      'onCollaborateChange',
      'promptAuthorize',
      'onMyUserContextChange',
    ],
  })
}

export async function checkIfUserIsInGuestMode(): Promise<boolean> {
  const zoomSdk = (await import('@zoom/appssdk')).default
  try {
    await initializeZoomSdk()
    const userContext = await zoomSdk.getUserContext()
    return userContext.status === 'unauthenticated'
  } catch (e) {
    return false
  }
}

export async function checkIfUserIsNotAuthorized(): Promise<boolean> {
  const zoomSdk = (await import('@zoom/appssdk')).default
  try {
    await initializeZoomSdk()
    const userContext = await zoomSdk.getUserContext()
    return userContext.status !== 'authorized'
  } catch (e) {
    return false
  }
}

export async function checkIfUserIsInZoomCollaborateMode(): Promise<boolean> {
  if (!device.zoomApp) {
    return false
  }
  const zoomSdk = (await import('@zoom/appssdk')).default

  try {
    await initializeZoomSdk()
    const runningContext = await zoomSdk.getRunningContext()
    return runningContext.context === 'inCollaborate'
  } catch (e) {
    return false
  }
}

export function isAttachmentSupportedByZoom(displayAttributes: {
  contentCategory?: BeethovenContentCategory | null
  contentSubcategory?: BeethovenContentSubcategory | null
  providerName?: string
}): boolean {
  // Return false for padlets because we want to open them within the Zoom window,
  // not in the browser
  if (displayAttributes.contentSubcategory === 'padlet') return false

  if (displayAttributes.contentCategory == null || displayAttributes.providerName == null) return true // Required for type check
  return (
    ['page', 'audio', 'document'].includes(displayAttributes.contentCategory) ||
    (displayAttributes.contentCategory === 'video' &&
      !['youtube', 'vimeo'].includes(displayAttributes.providerName.toLowerCase()))
  )
}

// Open url in the browser as we don't want to open it in the zoom app
// Zoom doesn't support navigation or embeds without OWASP headers
export async function openUrlInBrowserFromZoom(url: string): Promise<void> {
  const zoomSdk = (await import('@zoom/appssdk')).default
  zoomSdk.openUrl({ url }).catch(() => {})
}

// Hit end collaborate webhook so that we reset the isUserHostingZoomCollaboration session variable
export async function registerAsCollaborationHost(): Promise<void> {
  return await fetchJson(`/zoom/collaboration_host`, {
    method: HTTPMethod.put,
  })
}

export async function unregisterAsCollaborationHost(): Promise<void> {
  return await fetchJson(`/zoom/collaboration_host`, {
    method: HTTPMethod.delete,
  })
}

export async function promptAuthorize(): Promise<void> {
  const zoomSdk = (await import('@zoom/appssdk')).default
  try {
    await initializeZoomSdk()
    await zoomSdk.promptAuthorize()
    zoomSdk.onMyUserContextChange(() => {
      window.location.reload()
    })
  } catch (e) {
    captureException(e)
  }
}
